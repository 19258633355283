@tailwind base;

@tailwind components;

@tailwind utilities;

@import "font.css";
@import "hand.css";

a:hover {
  @apply text-secondary transition ease-in-out;
}

.block {
  @apply shadow-md p-7 mt-10;
  border-radius: 1.7rem;

  @media (min-width: 1024px) {
    @apply mt-15 p-10;
    border-radius: 2.5rem;
  }

  &-primary {
    @apply bg-primary text-white;
  }

  &-secondary {
    @apply bg-secondary text-white;
  }

  &-light {
    @apply bg-light;

    .block-subheading {
      @apply opacity-60;
    }
  }

  &-heading {
    @apply text-2xl font-bold mt-4 tracking-wide;
  }

  &-subheading {
    @apply text-sm opacity-80 font-bold uppercase;
  }

  &-body {
    @apply mt-4 leading-relaxed;
  }

  &-footer {
    @apply rounded-b-none;
  }
}