@font-face {
    font-family: 'Megabyte';
    src: local('Megabyte Bold'), local('Megabyte-Bold'),
        url('/assets/fonts/Megabyte-Bold.woff2') format('woff2'),
        url('/assets/fonts/Megabyte-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Megabyte';
    src: local('Megabyte Regular'), local('Megabyte-Regular'),
        url('/assets/fonts/Megabyte-Regular.woff2') format('woff2'),
        url('/assets/fonts/Megabyte-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
