#hand-emoji {
  display: inline-block;
  animation-name: wave;
  animation-duration: 2.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-delay: 2.5s;
  transform-origin: 76% 67.5%;
}

/* The animation code */
@keyframes wave {
  5%, 15%, 25%, 35% {
    transform: rotateZ(20deg);
  }

  10%, 20%, 30%, 40% {
    transform: rotateZ(-30deg);
  }

  45% {
    transform: rotateZ(0deg);
  }
}